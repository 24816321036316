footer{
	width: 96%;
	background-color: #171717;
	color: #cccccc;
	font-size: 15px;
	font-family: 'samsungone300';
	padding-left:2%;
	padding-right: 2%;
	padding-top: 15px;
	padding-bottom: 15px;
}
