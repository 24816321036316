.logo{
	width: 15%;
	display: inline-block;
	margin: 0;
	vertical-align: top;
	padding-top: 10px;
	padding-bottom: 10px;
}

.logo img{
	width: 100%;
	max-width: 180px;
}

header span{
	padding-left: 0 !important;
	padding-right: 0 !important;
}

#cssmenu #head-mobile{display:none}
/**{margin:0;padding:0;text-decoration:none}*/
header{width:100%; border-bottom: 1px solid #cccccc; z-index: 5005;position: relative;}
header.header-2{position: fixed; z-index: 5005; background: #fff;}
.logo{position:relative;z-index:123;padding:10px;font:18px verdana;color:#6DDB07;float:left;width:13%}
.logo a{color:#6DDB07;}
nav{position:relative;width:85%;margin:0 auto;}
#cssmenu,#cssmenu ul,#cssmenu ul li,#cssmenu ul li a,#cssmenu #head-mobile{border:0;list-style:none;line-height:1;display:block;position:relative;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;box-sizing:border-box}
#cssmenu:after,#cssmenu > ul:after{content:".";display:block;clear:both;visibility:hidden;line-height:0;height:0}
#cssmenu{font-family: 'samsungone300' !important; margin-left: 0; margin-right: 0; width: 100%;}
#cssmenu > ul > li{float:right;}
#cssmenu > ul > li > a{padding:17px;font-size:15px; text-decoration:none; }
#cssmenu > ul > li:hover > a,#cssmenu ul li.active a{color:#133aaa;}
#cssmenu > ul > li:hover,#cssmenu ul li.active:hover,#cssmenu ul li.active,#cssmenu ul li.has-sub.active:hover{border-top:2px solid #133aaa;-webkit-transition:background .3s ease;-ms-transition:background .3s ease;transition:background .3s ease;}
#cssmenu > ul > li.has-sub > a{padding-right:30px;}
#cssmenu > ul > li.has-sub > a:after{position:absolute;top:22px;right:11px;width:8px;height:2px;display:block;background:#133aaa;content:''}
#cssmenu > ul > li.has-sub > a:before{position:absolute;top:19px;right:14px;display:block;width:2px;height:8px;background:#133aaa;content:'';-webkit-transition:all .25s ease;-ms-transition:all .25s ease;transition:all .25s ease}
#cssmenu > ul > li.has-sub:hover > a:before{top:23px;height:0}
#cssmenu ul ul{position:absolute;left:-9999px}
#cssmenu ul ul li{height:45px !important; -webkit-transition:all .25s ease;-ms-transition:all .25s ease;background:#eeeeee;transition:all .25s ease; border-bottom: 1px solid #cccccc;}
#cssmenu ul ul li:hover{}
#cssmenu li:hover > ul{left:auto}
#cssmenu li:hover > ul > li{height:35px}
#cssmenu ul ul ul{margin-left:75%;top:0}
#cssmenu ul ul li a{border-bottom:1px solid rgba(150,150,150,0.15);padding:11px 15px;width:170px;font-size:15px;text-decoration:none;color:#333; padding-top:15px; padding-bottom:15px;}
#cssmenu ul ul li:last-child > a,#cssmenu ul ul li.last-item > a{border-bottom:0}
#cssmenu ul ul li:hover > a,#cssmenu ul ul li a:hover{color:#133aaa; background-color: #dddddd;}
#cssmenu ul ul li.has-sub > a:after{position:absolute;top:16px;right:11px;width:8px;height:2px;display:block;background:#133aaa;content:''; }
#cssmenu ul ul li.has-sub > a:before{position:absolute;top:13px;right:14px;display:block;width:2px;height:8px;background:#133aaa;content:'';-webkit-transition:all .25s ease;-ms-transition:all .25s ease;transition:all .25s ease}
#cssmenu ul ul > li.has-sub:hover > a:before{top:17px;height:0}
#cssmenu ul ul li.has-sub:hover,#cssmenu ul li.has-sub ul li.has-sub ul li:hover{background:#dddddd; }
#cssmenu ul ul ul li.active a{border-left:1px solid #333}
#cssmenu > ul > li.has-sub > ul > li.active > a,#cssmenu > ul ul > li.has-sub > ul > li.active> a{border-top:1px solid #333; }
#cssmenu > ul{ margin-bottom: 0 !important; margin-top: 0 !important;}

@media screen and (max-width:1000px){
.logo{position:absolute;top:0;left: 0;width:100%;height:46px;text-align:center;padding:10px 0 0 0 ;float:none}
.logo2{display:none}
nav{width:100%;}
#cssmenu{width:100%;}
#cssmenu ul{width:100%;display:none;}
#cssmenu ul li{width:100%;border-top:1px solid #cccccc;}
#cssmenu ul li:hover{background:#eeeeee;}
#cssmenu ul ul li,#cssmenu li:hover > ul > li{height:auto}
#cssmenu ul li a,#cssmenu ul ul li a{width:100%;border-bottom:0}
#cssmenu > ul > li{float:none}
#cssmenu ul ul li a{padding-left:25px}
#cssmenu ul ul li{background:#ddd!important;}
#cssmenu ul ul li:hover{}
#cssmenu ul ul ul li a{padding-left:35px}
#cssmenu ul ul li a{color:#333;}
#cssmenu ul ul li:hover > a,#cssmenu ul ul li.active > a{color:#133aaa; background-color: #eeeeee;}
#cssmenu ul ul,#cssmenu ul ul ul{position:relative;left:0;width:100%;margin:0;text-align:left}
#cssmenu > ul > li.has-sub > a:after,#cssmenu > ul > li.has-sub > a:before,#cssmenu ul ul > li.has-sub > a:after,#cssmenu ul ul > li.has-sub > a:before{display:none}
#cssmenu #head-mobile{display:block;padding:23px;color:#ddd;font-size:15px;font-weight:700;}
.button{width:55px;height:46px;position:absolute;right:0;top:0;cursor:pointer;z-index: 12399994;}
.button:after{position:absolute;top:22px;right:20px;display:block;height:4px;width:20px;border-top:2px solid #133aaa;border-bottom:2px solid #133aaa;content:''}
.button:before{-webkit-transition:all .3s ease;-ms-transition:all .3s ease;transition:all .3s ease;position:absolute;top:16px;right:20px;display:block;height:2px;width:20px;background:#133aaa;content:''}
.button.menu-opened:after{-webkit-transition:all .3s ease;-ms-transition:all .3s ease;transition:all .3s ease;top:23px;border:0;height:2px;width:19px;background:#eee;-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);-ms-transform:rotate(45deg);-o-transform:rotate(45deg);transform:rotate(45deg)}
.button.menu-opened:before{top:23px;background:#eee;width:19px;-webkit-transform:rotate(-45deg);-moz-transform:rotate(-45deg);-ms-transform:rotate(-45deg);-o-transform:rotate(-45deg);transform:rotate(-45deg)}
#cssmenu .submenu-button{position:absolute;z-index:99;right:0;top:0;display:block;border-left:1px solid #cccccc;height:46px;width:46px;cursor:pointer; color:#133aaa;}
#cssmenu .submenu-button.submenu-opened{background:#eeeeee;}
#cssmenu ul ul .submenu-button{height:34px;width:34px}
#cssmenu .submenu-button:after{position:absolute;top:22px;right:19px;width:8px;height:2px;display:block;background:#133aaa;content:''}
#cssmenu ul ul .submenu-button:after{top:15px;right:13px}
#cssmenu .submenu-button.submenu-opened:after{background:#133aaa;}
#cssmenu .submenu-button:before{position:absolute;top:19px;right:22px;display:block;width:2px;height:8px;background:#133aaa;content:''}
#cssmenu ul ul .submenu-button:before{top:12px;right:16px;}
#cssmenu .submenu-button.submenu-opened:before{display:none}
#cssmenu ul ul ul li.active a{border-left:none}
#cssmenu > ul > li.has-sub > ul > li.active > a,#cssmenu > ul ul > li.has-sub > ul > li.active > a{border-top:none}
}

