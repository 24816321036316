/* Generated by Font Squirrel (https://www.fontsquirrel.com) on March 17, 2017 */



@font-face {
    font-family: 'samsung_bold';
    src: url('../../../assets/fonts/samsungif-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/samsungif-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'samsung_medium';
    src: url('../../../assets/fonts/samsungif_md-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/samsungif_md-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'samsung_regular';
    src: url('../../../assets/fonts/samsungif_rg-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/samsungif_rg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'samsung_bold1';
    src: url('../../../assets/fonts/samsunginterface_bold-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/samsunginterface_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'samsung_bold2';
    src: url('../../../assets/fonts/samsungone-200_v1.0-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/samsungone-200_v1.0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'samsung_bold3';
    src: url('../../../assets/fonts/samsungone-800_v1.0-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/samsungone-800_v1.0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'samsungone300';
    src: url('../../../assets/fonts/samsungone-300_v1.0-webfont.woff2') format('woff2'),
         url('../../../assets/fonts/samsungone-300_v1.0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
	font-family: 'SamsungSharpSans';
	src: url('../../../assets/fonts/SamsungSharpSans-Bold.eot');
	src: url('../../../assets/fonts/SamsungSharpSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('../../../assets/fonts/SamsungSharpSans-Bold.woff') format('woff'),
		url('../../../assets/fonts/SamsungSharpSans-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'SamsungRegular';
	src: url('../../../assets/fonts/SamsungSharpSans-Regular.eot');
	src: url('../../../assets/fonts/SamsungSharpSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('../../../assets/fonts/SamsungSharpSans-Regular.woff') format('woff'),
		url('../../../assets/fonts/SamsungSharpSans-Regular.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'SamsungNarrow';
	src: url('../../../assets/fonts/SamsungOne-450_v1.0.eot');
	src: url('../../../assets/fonts/SamsungOne-450_v1.0.eot?#iefix') format('embedded-opentype'),
		url('../../../assets/fonts/SamsungOne-450_v1.0.woff') format('woff'),
		url('../../../assets/fonts/SamsungOne-450_v1.0.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}