html, body{
	padding: 0;
	margin: 0;
	font-family: 'SamsungRegular' !important;
	font-size: 1em;
	line-height: 1.4em;
	overflow-x: hidden;
}

body li{
	font-size: 14px;
}

a img {
	border: none !important;
}

input[type='email'], input[type='text']{
	height: 40px;
	border-radius: 30px;
	width: 94%;
	padding-left: 3%;
	padding-right: 3%;
	max-width: 500px;
	border: 1px solid #cccccc;
	font-size: 16px;
	font-family: 'SamsungRegular' !important;
	color:#1039ac;
	letter-spacing: 1px;
	margin-top:20px;
}

select{
	height:40px;
	background:none;
	border:1px solid #000000;
	border-radius:30px;
	padding:10px;
	width:100%;
	max-width:200px;
	text-transform:uppercase;
	margin-top:20px;
	margin-bottom:20px;
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: url(../img/icon-arrow-down-select.png) 96% / 15% no-repeat;
	background-size:15px auto;
	
}

table{
	width: 100%;
	font-size: 0.9em;
	background-color: #eeeeee;
	border: 1px solid #cccccc;
	padding: 5px 5px 0px 5px;
}

.mob{ display: none !important; }
.desktop{ display: inline-block !important;}


tr:nth-child(even) {
    background-color: #ffffff;
}

td{
	border-bottom: 1px solid #cccccc;
	padding-top: 15px;
}

.fw{ color:#ffffff; }
.fb{ color: #000000; }
.fblue{ color:#0042AE; }
.fgray{ color:#CCCCCC; }

p b, h2 b, h3 b{font-family: 'SamsungSharpSans' !important;}


h4{
	font-size: 4em;
	line-height: 1em;
	margin:5px;
}

h1{
	font-size: 3em;
	line-height: 1em;
	margin:5px;
}

h2{
	font-size: 1.5em;
	line-height: 1.2em;
	margin:5px;
}

h3{
	font-size: 0.8em;
	line-height: 1.5em;
	margin:5px;
}

.s1{
	font-family: 'samsungone300' !important;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: 1px;
}

.s1-small{
	font-family: 'samsungone300' !important;
	font-size: 14px;
	line-height: 24px;
}

.contenedor{
	width:100%;
	max-width: 1300px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

/*.row{
	width: 90%;
	padding-left: 5%;
	padding-right: 5%;
	padding-top:3%;
	padding-bottom:3%;
	margin: 0;
	position: relative;
}*/

.col1{
	width: 100%;
	margin: 0;
	margin-bottom: 0px;
	position: relative;
}

.col2{
	width: 49%;
	display: inline-block;
	margin: 0;
	margin-bottom: 50px;
	vertical-align: middle;
	position: relative;
}

.col3{
	width: 32%;
	display: inline-block;
	margin: 0;
	margin-bottom: 50px;
	position: relative;
}

.col4{
	width: 22%;
	display: inline-block;
	margin: 0;
	margin-bottom: 50px;
	position: relative;
	margin:1%;
	border-bottom:1px solid #ffffff;
}

.button-white{
	border: 1px solid #ffffff;
	color:#ffffff;
}

.button-white:hover{
	background-color: #ffffff;
	color:#000000;
}

.button-black{
	border: 1px solid #000000;
	color:#000000;
}

.button-black:hover{
	background-color: #000000;
	color:#ffffff;
}

.button-blue{
	border: 1px solid #004e91;
	color:#004e91;
	background-color:#ffffff;
}

.button-blue:hover{
	background-color: #004e91;
	color:#ffffff;
}

button{
	background: none;
	cursor: pointer;
	text-transform: uppercase;
	letter-spacing: 2px;
	margin:10px 5px 10px 0px;
	font-family: 'SamsungNarrow'; 
	padding: 20px 25px 20px 25px;
	border-radius:30px;
	font-size: 17px;
	line-height: 17px;
}

a{
	color: inherit;
	text-decoration: none;
}


.img-big{ width: 90%; }
.img-medium{ width: 50%; }
.patl{ position: absolute; top: 0px; left: 0px; margin-top: 10px; margin-left: 10px;}
.patr{ position: absolute; top: 0; right: 0; margin-top: 10px; margin-right: 10px;}
.pabl{ position: absolute; bottom: 0; left: 0; margin-bottom: 10px; margin-left: 10px;}
.pabr{ position: absolute; bottom: 0; right: 0; margin-bottom: 10px; margin-right: 10px;}



.blog-slide1{
	display: inline-block;
	width: 62.1%;
	margin: 0;
	padding: 0;
	position: relative;
}

.blog-slide1 h2{
	position: absolute;
	z-index: 5;
	left: 0;
	bottom: 0;
	margin-left: 10px;
	margin-bottom: 20px;
}



.blog-slide2{
	display: inline-block;
	width: 36.7%;
	margin: 0;
	padding: 0;
	position: relative;
}

.blog-slide2 .new{
	position: relative;	
}

.blog-slide2 .new span{
	position: absolute;
	z-index: 5;
	left: 0;
	bottom: 0;
	margin-left: 10px;
	margin-bottom: 10px;
}



.blog-slide1 img, .blog-slide2 img{
	width: 100%;
	margin: 0;
	padding: 0;
}

.tab-title{
	border: none;
	background: none;
	border-radius:0;
}

.blog-col2{
	display: inline-block;
	width: 45.5%;
	padding-left: 2%;
	padding-right: 2%;
}

.blog-col2 img{
	width: 90%;
	max-width: 280px;
	margin: 5%;
}

iframe{ width:640px; height:360px; border:1px solid #cccccc; max-width:90%; }

.t1{
	font-family: 'samsung_regular' !important;
	text-transform: uppercase !important;
	font-size:35px !important;
	line-height:40px !important;
}

.t2{
	font-family: 'samsung_regular' !important;
	text-transform: uppercase !important;
	font-size:40px !important;
	line-height:45px !important;
}


@media screen and (max-width:480px){
	.col2, .col3, .col4, .blog-slide1, .blog-slide2, .blog-col2{ width:95.5%; text-align:center !important;}
	.col4{margin:0; border-bottom:1px solid #cccccc; padding-bottom:20px;}
	h1,h4{font-size: 2.3em;}
	.col2{ text-align: center; }
	
	table{ font-size: 0.7em; }
	
	.mob{ display: inline-block !important; }
	.desktop{ display: none !important; }
	
	.slider-banner{
		padding-bottom: 400px !important;
	}
	
	.blog-col2{
		padding-bottom: 15px;
		margin-bottom: 15px;
		border-bottom:1px solid #cccccc;
	}
	
	.t1{
		font-size:20px !important;
		line-height:25px;
	}
	
	.t2{
		font-size:25px !important;
		line-height:30px;
	}
	
	@media only screen and (max-width: 639px) {	
		iframe{ width:100%; height:250px; }
	}
	
}

/* Responsive Nuevo */
@media screen and (max-width: 767px) {
	.col-dissapear { display: none; }
	.text-contain { padding: 2rem 1rem; text-align: center; }
}

@media screen and (max-width: 991px) {
	.text-contain { padding: 2rem 2rem; }
	.text-contain h1 { font-size: 2.5rem; margin: .6rem 0 1rem; }
	.img-contain-cell img { left: -6px; }
	.text-contain-sl2 { padding: 2rem; }
	.aj-att { max-height: 20px; }
	.s1.no-bottom { margin-bottom: 0; }
}

@media screen and ( min-width: 992px) {
	.aj-att { max-height: 45px; }
	.button-black.space-between { margin-bottom: 2rem; }
}